import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { firestore, storage } from '../../firebase';

const dateToLocalISO = (date) => {
  const off = date.getTimezoneOffset();
  const d = new Date(date.getTime() - (off * 60 * 1000));
  return d.toISOString().slice(0, 16);
};

const AdminAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState('');
  const [visible, setVisible] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const [editMode, setEditMode] = useState(false);
  const [editAnnouncementId, setEditAnnouncementId] = useState('');

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    const q = collection(firestore, 'announcement_slides');
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setAnnouncements(data);
  };

  const handleAddAnnouncement = async () => {
    if (!url) {
      alert('Please upload an image and wait for it to finish uploading before adding the announcement.');
      return;
    }

    const newData = {
      title: title || '',
      url,
      visible,
    };

    if (startDate) newData.startDate = new Date(startDate);
    if (endDate) newData.endDate = new Date(endDate);

    await addDoc(collection(firestore, 'announcement_slides'), newData);

    setTitle('');
    setImage(null);
    setUrl('');
    setVisible(true);
    setStartDate('');
    setEndDate('');
    setUploadProgress(0);

    await fetchAnnouncements();
  };

  const handleEditAnnouncement = (announcement) => {
    setEditMode(true);
    setEditAnnouncementId(announcement.id);
    setTitle(announcement.title || '');
    setUrl(announcement.url || '');
    setVisible(typeof announcement.visible === 'boolean' ? announcement.visible : true);
    setStartDate(announcement.startDate ? dateToLocalISO(announcement.startDate.toDate()) : '');
    setEndDate(announcement.endDate ? dateToLocalISO(announcement.endDate.toDate()) : '');
  };

  const handleUpdateAnnouncement = async () => {
    if (!url) {
      alert('Please upload an image and wait for it to finish before updating the announcement.');
      return;
    }

    const docRef = doc(firestore, 'announcement_slides', editAnnouncementId);
    const updatedData = {
      title: title || '',
      url,
      visible,
    };

    if (startDate) {
      updatedData.startDate = new Date(startDate);
    } else {
      updatedData.startDate = null;
    }

    if (endDate) {
      updatedData.endDate = new Date(endDate);
    } else {
      updatedData.endDate = null;
    }

    await updateDoc(docRef, updatedData);

    setTitle('');
    setImage(null);
    setUrl('');
    setVisible(true);
    setStartDate('');
    setEndDate('');
    setUploadProgress(0);
    setEditMode(false);
    setEditAnnouncementId('');

    await fetchAnnouncements();
  };

  const handleDeleteAnnouncement = async (id) => {
    const docRef = doc(firestore, 'announcement_slides', id);
    await deleteDoc(docRef);
    await fetchAnnouncements();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      console.log('No file selected or no file in input.');
      return;
    }

    setImage(file);
    const storageRef = ref(storage, `announcement_images/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Image upload failed:', error);
        alert('Image upload failed. Please try again or check the console for errors.');
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('Image uploaded successfully. URL:', downloadUrl);
        setUrl(downloadUrl);
      }
    );
  };

  return (
    <div className="admin-announcements">
      <h1>Admin Announcements</h1>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ display: 'block', marginBottom: '10px' }}
        />
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Upload Image:
          <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginLeft: '10px' }} />
        </label>
        {uploadProgress > 0 && uploadProgress < 100 && <p>Upload in Progress: {Math.round(uploadProgress)}%</p>}
        {uploadProgress === 100 && !url && <p>Processing image, please wait...</p>}
        {url && (
          <>
            <p>Upload Complete!</p>
            <img src={url} alt="Preview" style={{ maxWidth: '200px', marginBottom: '10px' }} />
          </>
        )}
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Visible:
          <input
            type="checkbox"
            checked={visible}
            onChange={(e) => setVisible(e.target.checked)}
            style={{ marginLeft: '10px' }}
          />
        </label>
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Start Showing After (optional):
          <input
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
        </label>
        <label style={{ display: 'block', marginBottom: '10px' }}>
          Stop Showing After (optional):
          <input
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
        </label>

        {editMode ? (
          <button onClick={handleUpdateAnnouncement}>Update Announcement</button>
        ) : (
          <button onClick={handleAddAnnouncement}>Add Announcement</button>
        )}
      </div>

      <h2>Current Announcements</h2>
      <ul>
        {announcements.map((announcement) => (
          <li key={announcement.id} style={{ marginBottom: '20px' }}>
            <img
              src={announcement.url}
              alt={announcement.title}
              style={{ maxWidth: '200px', display: 'block', marginBottom: '10px' }}
            />
            <strong>{announcement.title}</strong>
            <br />
            Visible: {announcement.visible ? 'Yes' : 'No'}
            <br />
            Start Date: {announcement.startDate ? announcement.startDate.toDate().toLocaleString() : 'None'}
            <br />
            End Date: {announcement.endDate ? announcement.endDate.toDate().toLocaleString() : 'None'}
            <br />
            <button onClick={() => handleEditAnnouncement(announcement)} style={{ marginRight: '10px' }}>
              Edit
            </button>
            <button onClick={() => handleDeleteAnnouncement(announcement.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminAnnouncements;
