import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Slider from 'react-slick';
import './Announcements.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

function InslideAnnouncements() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      const now = new Date();

      // We query for visible slides. We'll do date filtering after fetching.
      const q = query(
        collection(firestore, 'announcement_slides'),
        where('visible', '==', true)
      );
      const querySnapshot = await getDocs(q);
      const slideData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Filter slides by date range (if startDate/endDate provided)
      const filteredSlides = slideData.filter((slide) => {
        const startDate = slide.startDate ? slide.startDate.toDate() : null;
        const endDate = slide.endDate ? slide.endDate.toDate() : null;
        
        // Check date conditions
        if (startDate && now < startDate) return false;
        if (endDate && now > endDate) return false;

        return true;
      });

      // Check if images exist before setting slides
      const validatedSlides = await Promise.all(
        filteredSlides.map(async (slide) => {
          const imageExists = await checkImageExists(slide.url);
          return imageExists ? slide : null;
        })
      );

      setSlides(validatedSlides.filter(slide => slide !== null));
    };

    fetchSlides();
  }, []);

  // Function to check if image URL exists
  const checkImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="announcements-page">
      {slides.length > 0 ? (
        <Slider {...sliderSettings}>
          {slides.map((slide) => (
            <div key={slide.id} className="slide">
              <img src={slide.url} alt={slide.title} className="slide-image" />
              {
                //<p className="slide-title">{slide.title}</p>
              }
            </div>
          ))}
        </Slider>
      ) : (
        <p className="no-announcements-message">No announcements to display at the moment.</p>
      )}
    </div>
  );
}

export default InslideAnnouncements;
